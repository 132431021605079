import React from 'react';
import {LotteryGameCardItem} from '../lottery.service';
import theme from '@/style';
import {NativeTouchableOpacity} from '@basicComponents/touchable-opacity';
import {goTo, getUrlParams, formatDate} from '@/utils';
import Price from '@/components/business/price';
import Text from '@/components/basic/text';
import {View, Image, ImageBackground, StyleSheet} from 'react-native';
import LazyImage from '@/components/basic/image';
import {toGame} from '@/common-pages/game-navigate';
import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import {useTranslation} from 'react-i18next';
import {SafeAny} from '@/types';
import LinearGradient from '@/components/basic/linear-gradient/linear-gradient';
import {colors} from './constant';
import globalStore from '@/services/global.state';
import {useToast} from '@basicComponents/modal';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';

interface IObject {
  result: string;
  isClose: string;
  draw: [string, string];
}

interface LotteryFloorGamesProps {
  icon: string;
  title: string;
  list: LotteryGameCardItem[];
  type: string;
  serverTime?: number;
}
const LotteryFloorGames: React.FC<LotteryFloorGamesProps> = ({
  list,
  icon,
  title,
  type,
  serverTime = new Date().getTime(),
}) => {
  const {i18n} = useTranslation();
  const notYetWarning: {type: 'warning' | 'success'; message: string} = {
    type: 'warning',
    message: i18n.t('warning.unopen'),
  };
  const targetList = React.useMemo(() => {
    if (type === 'indianLottery') {
      const target = list.map(v => {
        v.lottoInfo = getUrlParams(
          v.gameUrl,
        ) as LotteryGameCardItem['lottoInfo'];
        v.otherInfo = getUrlParams(
          v.otherParams,
        ) as LotteryGameCardItem['otherInfo'];
        return v;
      });

      const closed = target.filter(
        item =>
          item.lottoInfo?.gameType !== 'quick' &&
          parseInt(item.otherInfo!.stopTime, 10) * 1000 < serverTime,
      );
      const other = target
        .filter(
          item =>
            item.lottoInfo?.gameType === 'quick' ||
            parseInt(item.otherInfo!.stopTime, 10) * 1000 > serverTime,
        )
        .sort((a, b) => {
          if (b.lottoInfo?.gameType === 'quick') {
            return 1;
          }
          return Number(a.otherInfo!.stopTime) - Number(b.otherInfo!.stopTime);
        });
      return other.concat(closed);
    }
    return list;
  }, [list, type, serverTime]);
  const {calcActualSize, screenWidth} = useScreenSize();
  const styles = StyleSheet.create({
    titleBox: {
      height: calcActualSize(40),
    },
    position: {
      top: 0,
      left: calcActualSize(-6),
      width: calcActualSize(92),
      height: calcActualSize(92),
    },
    shadowBox: {
      width: calcActualSize(92),
      height: calcActualSize(92),
    },
    shadowText: {
      textShadowOffset: {width: 0, height: calcActualSize(2)},
      textShadowRadius: 6,
      textShadowColor: 'rgba(0, 0, 0, 0.50)',
    },
    sataResultBox: {
      paddingHorizontal: calcActualSize(12),
      paddingVertical: calcActualSize(2),
      backgroundColor: 'rgba(255, 255, 255, 0.80)',
      borderRadius: calcActualSize(19),
      height: calcActualSize(21),
    },
    mask: {
      backgroundColor: 'rgba(0, 0, 0, 0.50)',
      zIndex: 10,
    },
    fixSafari: {
      letterSpacing: -0.4,
    },
  });
  const floorConfig: {[key: string]: {[key: string]: SafeAny}} = {
    lotteryV3: {
      bg: require('@assets/imgs/home/home-floor-title-bg.webp'),
      width: calcActualSize(111.66),
      height: calcActualSize(156),
      gap: calcActualSize(8),
      title: i18n.t('dicePopular'),
    },
    indianLottery: {
      bg: require('@assets/imgs/home/home-floor-title-bg.webp'),
      width: calcActualSize(111.66),
      height: calcActualSize(156),
      title: i18n.t('indianLottery'),
    },
    matkaV3: {
      bg: require('@assets/imgs/home/home-floor-title-bg.webp'),
      width: calcActualSize(111.66),
      height: calcActualSize(92),
      title: '',
    },
  };

  const floorTitleTrack: {[key: string]: string} = {
    lotteryV3: 'LOTTERY',
    indianLottery: 'STATELOTTERY',
    matkaV3: 'MATKA',
  };

  const renderClose = (
    <View
      style={[
        theme.fill.fill,
        theme.position.abs,
        theme.flex.flex,
        theme.flex.center,
        styles.mask,
      ]}>
      <Image
        source={require('@assets/imgs/close.webp')}
        style={[
          {
            width: calcActualSize(88),
            height: calcActualSize(88),
          },
        ]}
      />
    </View>
  );

  const {renderModal, show} = useToast();

  const handlerUrl = (url: string) => {
    if (!url) {
      return {};
    }
    const params = url.split('?')[1];
    if (!params) {
      return {};
    }
    return params.split('&').reduce((res, cur) => {
      if (cur.includes('=')) {
        const [key, val] = cur.split('=');
        res[key] = val;
      }
      return res;
    }, {} as {[key: string]: string});
  };

  const jumpGame = (v: LotteryGameCardItem) => {
    if (type === 'indianLottery') {
      const lottoInfo = getUrlParams(v.gameUrl) as {
        lotteryId: string;
        wonAmount: `${number}`;
        gameType?: string;
      };
      const other = getUrlParams(v.otherParams) as {
        cycle?: string;
        stopTime?: string;
      };
      if (
        getIndianLotteryData(v).time < new Date().getTime() &&
        lottoInfo.gameType !== 'quick'
      ) {
        show({
          type: 'warning',
          message: `${v.name} is closed now, please choose other game.`,
        });
        return;
      }
      goTo('MixLottery', {
        lotteryID: lottoInfo.lotteryId,
        isQuick: lottoInfo.gameType === 'quick' ? '1' : '0',
        cycle: other.cycle ? +other.cycle : 0,
      });
      return;
    }
    if (type === 'matkaV3') {
      if (+getmatkaV3Data(v).isClose === 2) {
        show({
          type: 'warning',
          message: `${v.name} is closed now, please choose other game.`,
        });
        return;
      }
      goTo('Satta', (v as SafeAny).item);
      return;
    }
    const params = handlerUrl(v.gameUrl);
    if (params.name?.toLowerCase() === 'dice') {
      params.cycle = '1';
      goTo('Dice', params);
    } else if (params.name?.toLowerCase() === 'color') {
      goTo('Color', getUrlParams(v.otherParams));
    } else if (v.name.toLowerCase() === 'state lottery') {
      goTo('Lottery');
    } else if (v.name.toLowerCase() === 'racecar') {
      toGame(v);
    }
    // else if (params.name?.toLowerCase() === 'satta') {
    //   goTo('Satta', (v as SafeAny).item);
    // }
    else {
      globalStore.globalTotal.next(notYetWarning);
    }
  };

  const getLastOr0 = (arr: SafeAny[]) => arr[arr.length - 1] || 0;

  const getIndianLotteryData = (v: LotteryGameCardItem) => {
    const price = +getLastOr0(v.gameUrl?.split('='));
    const time = +getLastOr0(v.otherParams?.split('=')) * 1000;
    return {price, time};
  };

  const getmatkaV3Data = (v: LotteryGameCardItem) => {
    if (!v.otherParams) {
      return {
        result: '***-**-***',
        draw: ['-', '-'],
      } as IObject;
    }
    const parseParams = v.otherParams.split('&').reduce((res, cur) => {
      if (cur.includes('=')) {
        const [key, value] = cur.split('=');
        res[key] = value;
      }
      return res;
    }, {} as {[key: string]: string});
    const {openResultNum, closeResultNum, openDraw, closeDraw, isClose} =
      parseParams;
    const openNum = openResultNum
      ? openResultNum.split('').reduce((s, y) => (s + +y) % 10, 0)
      : '*';
    const closeNum = closeResultNum
      ? closeResultNum.split('').reduce((s, y) => (s + +y) % 10, 0)
      : '*';
    const result = `${openResultNum || '***'}-${openNum}${closeNum}-${
      closeResultNum || '***'
    }`;
    return {result, draw: [openDraw || '-', closeDraw || '-'], isClose};
  };

  const renderItem = () => {
    return (
      <View
        style={[
          theme.flex.wrap,
          theme.flex.row,
          {
            width: screenWidth,
            gap: calcActualSize(theme.paddingSize.s),
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
          },
        ]}>
        {targetList?.map((v, i) => {
          /**
           * matkaV3
           * otherParams
           * openResultNum=123&closeResultNum=456&openDraw=03:10 PM&closeDraw=05:10 PM&isClose=0
           */
          // if (type === 'indianLottery' && i === 0) {
          // }
          return (
            <ExposureWrap
              key={i}
              message={`GAMETYPE_LOTTERY_${floorTitleTrack[type]}_${
                i + 1
              }_EXPOSURE`}>
              <NativeTouchableOpacity
                onPress={() => {
                  trackClick(
                    `GAMETYPE_LOTTERY_${floorTitleTrack[type]}_${i + 1}_TAP`,
                  );
                  jumpGame(v);
                }}
                key={i}
                style={[
                  theme.background.palegrey,
                  theme.borderRadius.m,
                  theme.overflow.hidden,
                  theme.position.rel,
                  {
                    width: floorConfig[type].width,
                    height: floorConfig[type].height,
                  },
                ]}>
                {type === 'lotteryV3' && (
                  <LazyImage
                    imageUrl={v.otherUrl! || v.gamePic}
                    width={'100%'}
                    height={'100%'}
                  />
                )}
                {type === 'indianLottery' && (
                  <>
                    {getIndianLotteryData(v).time < new Date().getTime() &&
                      getUrlParams(v.gameUrl).gameType !== 'quick' &&
                      renderClose}
                    <LinearGradient
                      style={[
                        theme.flex.flex,
                        theme.flex.col,
                        theme.flex.between,
                        theme.flex.centerByCol,
                        {
                          width: floorConfig[type]?.wdith,
                          height: floorConfig[type]?.height,
                          paddingTop: calcActualSize(theme.paddingSize.xxs),
                          paddingHorizontal: calcActualSize(
                            theme.paddingSize.l,
                          ),
                          paddingBottom: calcActualSize(theme.paddingSize.l),
                        },
                      ]}
                      start={{x: 0, y: 0}}
                      end={{x: 0, y: 1}}
                      colors={colors[i % 18]}>
                      <View
                        style={[
                          theme.position.rel,
                          theme.flex.flex,
                          theme.flex.col,
                          theme.flex.centerByCol,
                        ]}>
                        <View style={[theme.position.abs, styles.position]}>
                          <Image
                            source={require('@assets/imgs/home/lottery-img-light-bg.webp')}
                            style={styles.shadowBox}
                          />
                        </View>
                        <LazyImage
                          imageUrl={
                            getIndianLotteryData(v).time <
                              new Date().getTime() &&
                            getUrlParams(v.gameUrl).gameType !== 'quick'
                              ? v.otherUrl! || v.gamePic
                              : v.gamePic
                          }
                          width={calcActualSize(80)}
                          height={calcActualSize(80)}
                        />
                        <View
                          style={[
                            theme.flex.flex,
                            theme.flex.col,
                            theme.flex.centerByCol,
                            {
                              gap: calcActualSize(theme.paddingSize.xxs),
                            },
                          ]}>
                          <Price
                            price={+getUrlParams(v.gameUrl).wonAmount}
                            fixed={0}
                            textProps={{
                              color: theme.basicColor.white,
                              fontSize: 16,
                              fontWeight: '900',
                              style: {
                                lineHeight: calcActualSize(
                                  theme.paddingSize.xxl,
                                ),
                              },
                            }}
                          />
                          <Text
                            calc
                            fontSize={9}
                            fontWeight="900"
                            fontFamily="fontDin"
                            style={[
                              {
                                lineHeight: calcActualSize(
                                  (theme.paddingSize.xs / 2) * 3,
                                ),
                              },
                            ]}
                            color={theme.basicColor.white}>
                            {i18n.t('jackpot')}
                          </Text>
                        </View>
                      </View>
                      {
                        <View
                          style={[
                            theme.flex.flex,
                            theme.flex.col,
                            theme.flex.centerByCol,
                          ]}>
                          <Text
                            calc
                            fontSize={9}
                            fontWeight="700"
                            color={theme.basicColor.white}>
                            {getUrlParams(v.gameUrl).gameType !== 'quick'
                              ? 'Next Draw'
                              : 'Type'}
                          </Text>
                          <Text
                            calc
                            fontSize={9}
                            fontWeight="700"
                            color={theme.basicColor.white}>
                            {getUrlParams(v.gameUrl).gameType !== 'quick'
                              ? formatDate(
                                  getIndianLotteryData(v).time,
                                  'Mon ddsuffix HH:mm',
                                )
                              : getUrlParams(v.otherParams).cycle + ' minutes'}
                          </Text>
                        </View>
                      }
                    </LinearGradient>
                  </>
                )}
                {type === 'matkaV3' && (
                  <>
                    {+getmatkaV3Data(v).isClose === 2 && renderClose}
                    <LazyImage
                      imageUrl={v.otherUrl! || v.gamePic}
                      width={'100%'}
                      height={'100%'}
                    />
                    <View
                      style={[
                        theme.position.abs,
                        theme.fill.fill,
                        theme.flex.flex,
                        theme.flex.col,
                        {
                          padding: calcActualSize(theme.paddingSize.s),
                          gap: calcActualSize(theme.paddingSize.s),
                        },
                      ]}>
                      <Text
                        calc
                        fontSize={12}
                        fontWeight="900"
                        fontFamily="fontDin"
                        style={[styles.shadowText]}
                        color={theme.basicColor.white}>
                        {v.name}
                      </Text>
                      <View
                        style={[
                          theme.flex.flex,
                          theme.flex.row,
                          theme.flex.center,
                          theme.fill.fillW,
                          styles.sataResultBox,
                        ]}>
                        <Text
                          style={[styles.fixSafari]}
                          calc
                          primary
                          fontSize={14}
                          fontWeight="900"
                          fontFamily="fontDinBold">
                          {getmatkaV3Data(v).result}
                        </Text>
                      </View>
                      <View
                        style={[
                          theme.flex.flex,
                          theme.flex.row,
                          theme.flex.centerByRow,
                          theme.flex.between,
                        ]}>
                        {getmatkaV3Data(v).draw?.map((t, index) => (
                          <View key={index}>
                            <Text
                              calc
                              fontSize={10}
                              fontWeight="500"
                              color={'rgba(255, 255, 255, 0.60)'}>
                              {index === 0 ? 'Open' : 'Close'}
                            </Text>
                            <Text
                              calc
                              fontSize={10}
                              fontWeight="700"
                              color={theme.basicColor.white}>
                              {t}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>
                  </>
                )}
              </NativeTouchableOpacity>
            </ExposureWrap>
          );
        })}
      </View>
    );
  };
  return (
    <View style={[{paddingTop: calcActualSize(theme.paddingSize.l)}]}>
      <ImageBackground
        style={[
          styles.titleBox,
          theme.flex.row,
          theme.flex.centerByCol,
          {
            paddingHorizontal: calcActualSize(theme.paddingSize.l),
          },
        ]}
        source={floorConfig[type]?.bg}>
        {/* !!floorConfig[type]?.title */}
        <ExposureWrap
          message={`GAMETYPE_LOTTERY_${floorTitleTrack[type]}_BUTTON_EXPOSURE`}>
          <NativeTouchableOpacity
            onPress={() => {
              trackClick(
                `GAMETYPE_LOTTERY_${floorTitleTrack[type]}_BUTTON_TAP`,
              );
            }}
            style={[
              theme.flex.row,
              theme.position.rel,
              theme.flex.centerByCol,
              theme.fill.fillW,
            ]}>
            {icon ? (
              <Image
                source={{uri: icon}}
                style={[
                  {
                    width: calcActualSize(theme.iconSize.xxl),
                    height: calcActualSize(theme.iconSize.xxl),
                    marginRight: calcActualSize(theme.paddingSize.s),
                  },
                ]}
              />
            ) : (
              <View
                style={[
                  theme.background.palegrey,
                  {
                    width: calcActualSize(theme.iconSize.xxl),
                    height: calcActualSize(theme.iconSize.xxl),
                    marginRight: calcActualSize(theme.paddingSize.s),
                  },
                ]}
              />
            )}
            <Text calc size="medium" main blod>
              {title}
            </Text>
            {!!floorConfig[type]?.title && (
              <View
                style={[
                  theme.position.abs,
                  {
                    right: theme.paddingSize.zorro,
                  },
                ]}>
                <Text calc fontSize={10} primary blod letterSpacing={0}>
                  {(floorConfig[type]?.title || '').toUpperCase()}
                </Text>
              </View>
            )}
          </NativeTouchableOpacity>
        </ExposureWrap>
      </ImageBackground>
      {renderItem()}
      {renderModal}
    </View>
  );
};

export default LotteryFloorGames;
