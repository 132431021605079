import React from 'react';
import {View, StyleSheet} from 'react-native';
import Carousel from 'react-native-reanimated-carousel';
import theme from '@/style';
import {BasicObject} from '@/types';
import LazyImage from '@/components/basic/image';
import {NativeTouchableOpacity} from '@/components/basic/touchable-opacity';
import {useResponsiveDimensions} from '@/utils';
import ExposureWrap from '@/components/business/track-wrap/exposure-wrap';
import {trackClick} from '@/components/business/track-wrap/utils';
const LiveCarousel = ({
  bannerList = [],
  onPress = () => {},
}: {
  bannerList: BasicObject[];
  onPress: (info: BasicObject) => void;
}) => {
  const {width} = useResponsiveDimensions();

  const imageWidth = React.useMemo(() => {
    return (width * 147) / 375 + 8;
  }, [width]);

  return (
    <View style={[theme.margin.tbl, theme.padding.lrl]}>
      <Carousel
        width={imageWidth}
        height={(imageWidth / 448) * 256}
        vertical={false}
        loop
        style={[
          styles.container,
          bannerList.length === 0 && theme.background.grey,
        ]}
        autoPlay
        pagingEnabled
        autoPlayInterval={3000}
        data={bannerList}
        renderItem={({item, index}) => (
          <ExposureWrap
            message={`GAMETYPE_LIVE_RECOMMENDED_${index + 1}_EXPOSURE`}>
            <NativeTouchableOpacity
              onPress={() => {
                trackClick(`GAMETYPE_LIVE_RECOMMENDED_${index + 1}_TAP`);
                onPress(item);
              }}
              style={styles.imageContainer}>
              <LazyImage
                imageUrl={item.bannerImg}
                width={'100%'}
                height={'100%'}
              />
            </NativeTouchableOpacity>
          </ExposureWrap>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  imageContainer: {
    flex: 1,
    marginRight: 8,
    borderRadius: 8,
    overflow: 'hidden',
  },
});

export default LiveCarousel;
